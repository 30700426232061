<template>
  <travio-modal width='450' height="80" zIndex="99999">
    <h3 slot="header">{{title}}</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="">Original Name</label>
          <vs-input class="w-full" v-model="originalNameInEdit" v-validate="'required|max:50'" name="Original Name" />
          <span class="text-danger text-sm">{{ errors.first('Original Name') }}</span>
        </div>
      </div>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="saveLocation" type="filled">{{locationId > 0 ? 'Update' : 'Add'}}</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  components: {
    TravioModal
  },
  data () {
    return {
      originalNameInEdit: null,
    }
  },
  props: {
    locationId: { required: false }, 
    originalName: { required: false, default: '' }, 
  },
  computed: {
    title() {
      return this.locationId ? 'Edit Location Normalisation' : 'Add Location Normalisation'
    }
  },
  mounted (){
    if (this.locationId){
      this.originalNameInEdit = this.originalName
    }
  },
  methods: {
    async saveLocation () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      if(this.locationId) {
        this.$emit('onEditSuccess', { 
          locationId: this.locationId,
          originalName: this.originalNameInEdit
        })
      } else {
        this.$emit('onAddSuccess', this.originalNameInEdit)
      }
      
    },
    close () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>