<template>
  <travio-center-container grid-width='1/2'>
    <h2 class="mb-3" style="color:#636363"><span class="text-muted">Edit Location Normalisation</span></h2>
    <vx-card>
      <div class="flex flex-wrap justify-start mb-3">
        </div>
      <h3 style="color:#636363">{{normalisedName}}</h3>
      <div class="text-muted mt-2">Country Code: {{countryCode}}</div>
      <div class="flex flex-wrap justify-start mt-4 mb-3">
        <vs-button @click="addLocation" class="sm:mb-0 mb-2">Add</vs-button>
      </div>
      
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="locationList"
        :gridOptions="gridOptions"
        rowSelection="single"
        :pagination="false"
        :context="context"
        :suppressPaginationPanel="true"
        @first-data-rendered="onFirstDataRendered"
      >
      </ag-grid-vue>
      <div class="flex flex-wrap justify-end mt-3">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click="saveAll" class="mt-4">Save</vs-button>
      </div>
    </vx-card>
    <location-normalised-name-editor
      v-if="showLocationEditor"
      :locationId="locationIdInEdit"
      :originalName="originalNameInEdit"
      @onAddSuccess="onAddSuccess"
      @onEditSuccess="onEditSuccess"
      @onCancel="showLocationEditor=false"
    />
  </travio-center-container>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererOriginalNameEditorActions from './cell-renderers/CellRendererOriginalNameEditorActions.vue'
import LocationNormalisedNameEditor from './components/LocationNormalisedNameEditor.vue'
import { de } from 'date-fns/locale';
import { resourceUsage } from "process";

export default {
  components: {
    AgGridVue,
    CellRendererOriginalNameEditorActions,
    LocationNormalisedNameEditor
  },
  props: {
    countryCode: { required: true },
    normalisedName: { required: true },
  },
  data () {
    return {
      locationList: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererOriginalNameEditorActions
      },
      showLocationEditor: false,
      locationStateForEditor: {},
      context: null,
      locationIdInEdit: null,
      originalNameInEdit: null,
      newOriginalNames: [],
      editedOriginalNames: [],
      deletedLocationIds: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    isDirty() {
      if (this.newOriginalNames.length > 0 || this.editedOriginalNames.length > 0 || this.deletedLocationIds.length > 0) {
        return true
      } else {
        return false
      }

    }
  },
  async created () {
    this.$vs.loading()
    try {
      await this.loadData()
    } catch (error) {
      this.$_notifyFailure(error)
    }
    this.$vs.loading.close()
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'locationId', hide: true },
      { headerName: 'Original Name', field: 'originalName', width: 320,  suppressSizeToFit: false },
      { headerName: 'Actions', width: 150,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererOriginalNameEditorActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: false,
      resizable: true,
      suppressMenu: true
    }
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    async loadData() {
      const response = await this.$http.get(`api/admin/locationNormalisation/${this.countryCode}/${this.normalisedName}`)
      this.locationList = response.data.resultList
    },
    addLocation () {
      this.locationIdInEdit = null
      this.originalNameInEdit = null
      this.showLocationEditor = true
    },
    editLocation (itemInEdit) {
      this.locationIdInEdit = itemInEdit.locationId
      this.originalNameInEdit = itemInEdit.originalName
      this.showLocationEditor = true
    },
    handleCancel () {
      if (this.isDirty) {
        let confirmation = confirm("There are unsaved changes, are you sure you want to cancel?");
        if( confirmation ) {
          this.$router.push({name: "localisation-normalisation"});
        } else {
          return
        }
      }
      this.$router.push({name: "localisation-normalisation"});
    },
    async saveAll() {
      if (!this.isDirty) {
        this.$_notifyFailure("No pending changes to save.")
        return
      }
      this.$vs.loading()
      try {
        const response = await this.$http.put(`api/admin/locationNormalisation/bulk`, {
          normalisedName: this.normalisedName,
          countryCode: this.countryCode,
          newOriginalNames: this.newOriginalNames,
          editedOriginalNames: this.editedOriginalNames,
          deletedLocationIds: this.deletedLocationIds,
        })

        this.newOriginalNames = []
        this.editedOriginalNames = []
        this.deletedLocationIds = []
        if(this.locationList.length == 0 ){
          this.$router.push({name: "localisation-normalisation"})
          return
        }
        await this.loadData()
        this.$_notifySuccess('Successfully saved locations');
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    onAddSuccess (newOriginalName) { 
      this.locationList = [...this.locationList, { locationId: 0, originalName: newOriginalName }]
      this.newOriginalNames.push(newOriginalName)
      this.showLocationEditor = false
    },
    onEditSuccess (updatedState) {
      const index = this.locationList.findIndex(x => x.locationId == updatedState.locationId)
      this.locationList[index] = updatedState
      this.locationList = [...this.locationList]
      this.editedOriginalNames.push(updatedState)
      this.showLocationEditor = false
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    async deleteLocation (deletedItem) {
      if(deletedItem.locationId > 0) {
        const newLocationList = this.locationList.filter(x => x.locationId != deletedItem.locationId)
        this.locationList = [...newLocationList]
        this.deletedLocationIds.push(deletedItem.locationId)
        // ensure that deleted item is not in the edited list
        this.editedOriginalNames = this.editedOriginalNames.filter(x => x.locationId != deletedItem.locationId)
      } else {
        // 0 Location id means it got added but not yet save
        const newLocationList = this.locationList.filter(x => x.locationId != deletedItem.locationId && x.originalName != deletedItem.originalName )
        this.locationList = [...newLocationList]
        //remove it from the new original names
        this.newOriginalNames = this.newOriginalNames.filter(x => x !== deletedItem.originalName)
      }
      
      
    },
  }
}
</script>

<style>

</style>